<template>
  <div :class="[$style.notification, isVisible]" :style="cssStyles">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

const MOBILE_WIDTH = 991

export default defineComponent({
  name: 'BottomNotification',

  data() {
    return {
      visible: false,

      left: 0,
      isMobile: false,
    }
  },

  computed: {
    offset() {
      return this.isMobile ? 16 : 32
    },

    cssStyles() {
      return {
        left: `${this.left + this.offset}px`,
      }
    },

    isVisible() {
      return this.visible ? 'd-block' : 'd-none'
    },
  },

  mounted() {
    this.setOffsets()

    window.addEventListener('resize', this.setOffsets)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setOffsets)
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.visible = true
    },

    /**
     * @public
     */
    hide() {
      this.visible = false
    },

    setOffsets() {
      const { offsetLeft } = this.$el.closest('main')

      this.isMobile = window.innerWidth < MOBILE_WIDTH
      this.left = offsetLeft
    },
  },
})
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.notification {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  background: $white;
  border: 1px solid rgba(0, 119, 215, 0.24);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 4px 24px 0px rgba(51, 47, 62, 0.08);

  @media (min-width: $lg) {
    right: 32px;
  }
}
</style>
