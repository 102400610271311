import { hasJustPaidContractDemoFeSimple } from '@frontend/helpers/demoFeSimple/student'
import { hasJustPaidContractFe } from '@frontend/helpers/fe/student'
import Constants from 'Constants'
import { feature } from 'Helpers/feature'
import { isNil } from 'lodash'

const filterTypes = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
  ADDITIONAL: 'ADDITIONAL',
  EXTRACURRICULAR: 'EXTRACURRICULAR',
}

const _filterBySubjectTypeExtracurricular = (subject) => subject.fromShowcase

export default {
  _filterSubjects() {
    return (filterType) => {
      switch (filterType) {
        case filterTypes.EXTRACURRICULAR:
          return _filterBySubjectTypeExtracurricular

        default:
          return []
      }
    }
  },

  hasStudents(state) {
    return state.students.length > 0
  },

  hasStudentJustPaidContractFe(state, getters) {
    return getters.studentJustPaidContractFe !== null
  },

  hasStudentJustPaidContractDemoFeSimple(state, getters) {
    return getters.studentJustPaidContractDemoFeSimple !== null
  },

  hasEnrolledStudents(state, getters) {
    return getters.enrolledStudents.length > 0
  },

  studentJustPaidContractFe(state) {
    return state.students.find(hasJustPaidContractFe) ?? null
  },

  studentJustPaidContractDemoFeSimple(state) {
    return state.students.find(hasJustPaidContractDemoFeSimple) ?? null
  },

  enrolledStudents(state) {
    const isActive = (student) =>
      student.accountStatus?.id === Constants.accountStatus.ACTIVE
    const isEnrolled = (student) =>
      student.enrollmentStatus?.id === Constants.enrollmentStatus.ENROLLED

    return state.students.filter(
      (student) =>
        student.hasPaidOnlineSchool ||
        (isActive(student) &&
          (isEnrolled(student) || student.hasIncomeDiagnostics)),
    )
  },

  schoolPolicyFileUrl(state) {
    return state.school?.policyFile?.fileUrl ?? ''
  },

  schoolPDFileUrl(state) {
    return state.school?.personalDataFile?.fileUrl
  },

  /**
   * @param {Object} state
   * @returns {Object}
   */
  lastMonthStat(state) {
    return state.analytics?.lastMonthStat || {}
  },

  currentStudentStreamAcademicYearPreset(state) {
    return state.currentStudent?.streamAcademicYearPreset
  },

  isCurrentStudentDemo(state) {
    return Boolean(state.currentStudent?.isDemo)
  },

  getCurrentStudentDemoAccessTime(state) {
    return Number(state.currentStudent?.demoAccessTime)
  },

  getCurrentStudentSchoolDemoMessage(state) {
    let demoMessage = ''

    switch (state.currentStudent?.educationType?.id) {
      case Constants.educationType.EXTRAMURAL:
        demoMessage = state.currentStudent?.school?.demoMessage
        break
      case Constants.educationType.FAMILY:
        demoMessage = state.currentStudent?.school?.demoMessageFe
        break

      default:
        break
    }

    return demoMessage
  },

  currentStudentId(state) {
    return state.currentStudent.id
  },

  educationType(state) {
    const { EXTRAMURAL, FAMILY, FULL_TIME } = Constants.educationType
    let id = state.currentStudent?.educationType?.id

    const hasOnlineSchool = state.currentStudent?.hasPaidOnlineSchool

    if (hasOnlineSchool && id !== FAMILY && id !== EXTRAMURAL) {
      id = EXTRAMURAL
    }

    return {
      extramural: id === EXTRAMURAL,
      family: id === FAMILY,
      fullTime: id === FULL_TIME,
    }
  },

  hasEducationType(state) {
    return !isNil(state.currentStudent?.educationType)
  },

  isStudentEnrolledAndHasDiagnostics(state) {
    return (
      feature.isIncomeDiagnostics &&
      state.currentStudent?.enrollmentStatus?.id !==
        Constants.enrollmentStatus.ENROLLED &&
      state.currentStudent?.hasIncomeDiagnostics
    )
  },

  canStudentsJoinLibrary(state) {
    return state.students.some((student) => student.canJoinLibrary)
  },

  getSubjectsExtracurricular(state, getters) {
    return state.activeSubjects.filter(
      getters._filterSubjects(filterTypes.EXTRACURRICULAR),
    )
  },
}
