import { StoreUser } from '@frontend/StoreUser'
import ClientMain from '@frontend/layouts/ClientMain.vue'
import { clientCourseRoutes } from '@frontend/routes/client/clientCourseRoutes'
import { clientMainRoutes } from '@frontend/routes/client/clientMainRoutes'
import { clientOnlineSchoolRoutes } from '@frontend/routes/client/clientOnlineSchoolRoutes'
import { clientPaymentRoutes } from '@frontend/routes/client/clientPaymentRoutes'
import { clientSupportRoutes } from '@frontend/routes/client/clientSupportRoutes'

const checkRole = (to, from, next) => {
  const isClient = StoreUser.store.getters['common/user/isClient']

  if (isClient) {
    next()
  } else {
    next({
      name: 'login',
    })
  }
}

export const clientRoutes = [
  {
    path: '/client',
    component: ClientMain,
    children: [
      ...clientCourseRoutes,
      ...clientMainRoutes,
      ...clientSupportRoutes,
      ...clientPaymentRoutes,
      ...clientOnlineSchoolRoutes,
    ],
  },
]

clientRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
