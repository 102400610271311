import client from '@frontend/store/onlineSchool/client/store'
import common from '@frontend/store/onlineSchool/common/store'
import student from '@frontend/store/onlineSchool/student/store'

export default {
  namespaced: true,

  modules: {
    client,
    common,
    student,
  },
}
