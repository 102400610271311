import purchase from '@frontend/store/onlineSchool/common/purchase/store'
import tariff from '@frontend/store/onlineSchool/common/tariff/store'

export default {
  namespaced: true,

  modules: {
    tariff,
    purchase,
  },
}
