import { studentTypes } from '@frontend/consts/client/onlineSchool/studentTypes'
import { gradeOptions } from '@frontend/helpers/onlineSchool/grades'

export default () => ({
  initialOrder: null,
  currentOrder: {
    isEditable: true,
  },
  invoicePeriodType: null,
  studentConsumer: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    grade: gradeOptions[0],
  },
  products: [],
  purchase: null,
  invoices: [],
  options: {
    paymentTypes: [],
    students: [],
  },
  isLoadingProducts: false,

  currentStudentType: studentTypes[0],
  currentStudent: null,
})
