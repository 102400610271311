import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProfile(state, payload) {
    state.profile = payload
  },

  setProfileValue(state, { key, value }) {
    Vue.set(state.profile, key, value)
  },

  setProfilePassword(state, payload) {
    state.passwords = payload
  },

  setEventsTelegramIsLinked(state, payload) {
    state.events.telegramBot.isLinked = payload
  },

  setEventsTelegramLink(state, payload) {
    state.events.telegramBot.link = payload
  },

  setUserSettings(state, payload) {
    state.userSettings = payload
  },

  setSchoolPlatform(state, payload) {
    state.schoolPlatform = payload
  },

  setSubdomains(state, payload) {
    state.subDomains = payload
  },
}
