<template>
  <LoaderFullScreen v-if="isLoading" />

  <LayoutBase v-else :main="main" :noticed="isDemoProfile">
    <template #header>
      <TheHeaderStudent
        v-if="isRendered.header"
        :abbreviation="nameAbbreviation"
        :avatar="avatar"
        :full-name="fullNameWithShortLastName"
        :grade="Number(profile.grade)"
        :letter="letter"
        :has-blocking-certifications="hasBlockingCertifications"
        :links="getLinks"
        :notice-additional-message="demoMessage"
        :notice-text="demoAccessTimeText"
        :rating="profile.rating"
        :credit="profile.credit"
        :school-name="schoolName"
        @change-grade="onChangeGrade"
      />

      <RateUs
        :profile="profile"
        :education-type="educationType"
        @change-frame-state="onChangeFrameRateUsState"
      />
    </template>

    <template #aside>
      <TheAside
        :links="getLinks"
        :user-settings="profile.userSettings"
        @toggle-aside="toggleAside"
      />
    </template>

    <template #tabs-nav>
      <TabsNav
        :current-tab="currentTab"
        :tabs="mainTabsFiltered"
        @change="onChangeTabNav"
      />
    </template>

    <template #subjects>
      <Subjects
        :current-tab="currentTab"
        :subjects-additional="getSubjectsAdditional"
        :subjects-optional="getSubjectsOptional"
        :subjects-required="getSubjectsRequired"
        :subjects-extracurricular="getSubjectsExtracurricular"
      />
    </template>

    <router-view />

    <template #footer>
      <TheFooter :school="profile.school" :user-id="profile.id" />
    </template>

    <template #bottom-notification>
      <BlockChooseEducationForm ref="BlockChooseEducationForm" />
    </template>

    <BtnInfoStudent
      :is-btn-info-shifted="isBtnInfoShifted"
      @click="onClickBtnInfo"
    />

    <BtnUp @visibility-changed="onVisibilityChanged" />

    <ModalProfileUpdate
      ref="modalProfileUpdateDemoFeSimple"
      :student="profileUpdate.student"
      :form="demoFeSimpleForm"
      :set-form-prop="setDemoFeSimpleFormProp"
      :fetch-form="fetchDemoFeSimpleForm"
      :update-form="updateDemoFeSimpleForm"
    />

    <ModalDomenMoved ref="ModalDomenMoved" />
  </LayoutBase>
</template>

<script>
import storageApi from '@frontend/helpers/storage'
import ModalDomenMoved from '@frontend/components/common/ModalDomenMoved.vue'
import BlockChooseEducationForm from '@frontend/components/student/main/BlockChooseEducationForm.vue'
import ModalProfileUpdate from '@frontend/components/common/ModalProfileUpdate.vue'
import RateUs from '@frontend/components/common/RateUs.vue'
import TabsNav from '@frontend/components/common/TabsNav.vue'
import Subjects from '@frontend/components/student/main/Subjects.vue'
import getEstimatedTime from '@frontend/helpers/getEstimatedTime'
import { mainTabsStudent } from '@frontend/helpers/mainTabs'
import LayoutBase from '@frontend/layouts/common/main/LayoutBase.vue'
import TheAside from '@frontend/layouts/common/TheAside.vue'
import TheFooter from '@frontend/layouts/common/TheFooter.vue'
import TheHeaderStudent from '@frontend/layouts/common/TheHeaderStudent.vue'
import BtnUp from '@frontend/ui/BtnUp.vue'
import BtnInfoStudent from '@frontend/components/student/main/BtnInfoStudent.vue'
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import dateFormat from 'Helpers/const/dateFormat'
import {
  getDate,
  getEndOfBusinessWeek,
  getStartOfWeek,
} from 'Helpers/dateFormatHelper'
import { isUndefined } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const MOBILE_WIDTH = 991
const INTERVAL_TIME = 60000 // 1min
const now = new Date()

export default {
  name: 'StudentMain',

  components: {
    BlockChooseEducationForm,
    BtnInfoStudent,
    ModalProfileUpdate,
    ModalDomenMoved,
    LoaderFullScreen,
    LayoutBase,
    TheHeaderStudent,
    TheAside,
    TheFooter,
    Subjects,
    TabsNav,
    BtnUp,
    RateUs,
  },

  /**
   * @param {Object} to
   * @param {Object} from
   * @param {Function} next
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.fetchActiveModules())
  },

  data() {
    return {
      main: false,
      isLoading: false,
      demoAccessTimeText: '',
      demoTimer: null,
      demoTimerSecondsLeft: 0,
      isBtnInfoShifted: false,
      isFrameRateUsOpened: false,

      profileUpdate: {
        student: null,
      },
    }
  },

  computed: {
    ...mapState('student/main', [
      'currentTab',
    ]),

    ...mapState('student/profile', [
      'profile',
    ]),

    ...mapState('student/demoFeSimple', {
      demoFeSimpleForm: 'form',
    }),

    ...mapGetters('student/main', [
      'getLinks',
    ]),

    ...mapGetters('student/profile', [
      'fullNameWithShortLastName',
      'nameAbbreviation',
      'demoAccessTime', // seconds
      'demoMessage',
      'isDemoProfile',
      'hasEducationType',
      'educationType',
      'onlyDiagnostics',
      'hasJustPaidContractFe',
      'hasJustPaidContractDemoFeSimple',
      'isAvailableAnyCommercialOffer',
    ]),

    ...mapGetters('student/subjects', [
      'getSubjectsRequired',
      'getSubjectsOptional',
      'getSubjectsAdditional',
      'getSubjectsExtracurricular',
    ]),

    ...mapGetters('student/extramuralCertification', [
      'hasBlockingCertifications',
    ]),

    ...mapGetters('common/module', [
      'hasModules',
    ]),

    avatar() {
      return this.profile.photo?.thumbs?.small ?? ''
    },

    schoolName() {
      return this.profile.school?.name ?? ''
    },

    letter() {
      return this.profile.letter ?? ''
    },

    isMobile() {
      return window.innerWidth < MOBILE_WIDTH
    },

    isRendered() {
      return {
        header: !(this.isMobile && this.isFrameRateUsOpened),
      }
    },

    mainTabsFiltered() {
      let mainTabs = null

      mainTabs = Object.fromEntries(
        Object.entries(mainTabsStudent).filter(
          ([
            ,
            value,
          ]) =>
            isUndefined(value.educationTypes) ||
            value.educationTypes.includes(this.profile.educationType?.id),
        ),
      )

      if (!this.profile.hasPhysiconAccess) {
        mainTabs = Object.fromEntries(
          Object.entries(mainTabs).filter(
            ([
              ,
              value,
            ]) => value.id !== 'physicon',
          ),
        )
      }

      return mainTabs
    },
  },

  watch: {
    /**
     * layout на главной странице отличается от второстепенных страниц
     * по структуре и передается Boolean соответствующий
     */
    $route: {
      immediate: true,
      handler() {
        this.main = this.$route.name === 'student-main'
      },
    },
  },

  created() {
    this.init()
  },

  beforeDestroy() {
    this.releaseTimerForDemoText()
    this.stopPollingMessages()
  },

  methods: {
    ...mapActions({
      startPollingMessages: 'common/messages/startPollingMessages',
      stopPollingMessages: 'common/messages/stopPollingMessages',
      fetchCommonNotifications: 'common/notifications/fetchNotifications',
      fetchPromoProducts: 'common/promoCampaign/fetchPromoProducts',
      fetchPromoCtps: 'common/promoCampaign/fetchPromoCtps',
      fetchUserSettings: 'student/profile/fetchUserSettings',
      updateUserSettings: 'student/profile/updateUserSettings',
      fetchProfile: 'student/profile/fetchProfile',
      fetchNotifications: 'student/profile/fetchNotifications',
      fetchSubjects: 'student/subjects/fetchSubjects',
      fetchLessons: 'student/lessons/fetchLessons',
      fetchHomeworkDates: 'student/homework/fetchHomeworkDates',
      fetchJournal: 'student/journal/fetchJournal',
      fetchGoals: 'student/goals/fetchGoals',
      fetchExtramuralCertifications:
        'student/extramuralCertification/fetchCertifications',
      fetchFamilyCertifications:
        'student/familyCertification/fetchCertifications',
      fetchFullTimeCertifications:
        'student/fullTimeCertification/fetchCertifications',
      fetchDemoFeSimpleForm: 'student/demoFeSimple/fetchForm',
      updateDemoFeSimpleForm: 'student/demoFeSimple/updateForm',
      addBottomNotification: 'student/notifications/addBottomNotification',
      // eslint-disable-next-line vue/no-unused-properties
      fetchActiveModules: 'common/module/fetchActiveModules',
    }),

    ...mapMutations({
      setCurrentTab: 'student/main/setCurrentTab',
      setDemoFeSimpleFormProp: 'student/demoFeSimple/setFormProp',
    }),

    async init() {
      this.isLoading = true

      // TODO: перенести все экшны в один общий экшн
      try {
        const requests = []

        requests.push(this.fetchSubjects())

        if (!this.hasEducationType) {
          requests.push(
            this.addBottomNotification({
              blockName: 'BlockChooseEducationForm',
            }),
          )
        }

        if (this.isAvailableAnyCommercialOffer) {
          requests.push(this.fetchPromoProducts(), this.fetchPromoCtps())
        }

        if (this.educationType.fullTime) {
          const startDate = getDate(
            getStartOfWeek(now),
            dateFormat.DATE_FORMAT_BACKEND,
          )
          const endDate = getDate(
            getEndOfBusinessWeek(now),
            dateFormat.DATE_FORMAT_BACKEND,
          )
          const journalParams = {
            startDate,
            endDate,
          }

          requests.push(
            this.fetchHomeworkDates(),
            this.fetchNotifications(),
            this.fetchJournal(journalParams),
          )
        }

        if (this.$route.name !== 'certification') {
          if (this.educationType.extramural) {
            requests.push(this.fetchExtramuralCertifications())
          }

          if (this.educationType.family) {
            requests.push(
              this.fetchFamilyCertifications(),
              this.fetchExtramuralCertifications(),
            )
          }

          if (this.educationType.fullTime) {
            requests.push(this.fetchFullTimeCertifications())
          }
        }

        if (this.$route.name !== 'lessons') {
          requests.push(this.fetchLessons())
        }

        if (this.$route.name !== 'goals') {
          requests.push(this.fetchGoals())
        }

        if (this.$route.name !== 'notifications') {
          requests.push(this.fetchCommonNotifications())
        }

        await this.fetchUserSettings()

        await Promise.all(requests)

        if (
          this.hasModules([
            'chat',
          ])
        ) {
          await this.startPollingMessages()
        }

        if (this.isDemoProfile) {
          this.setTimerForDemoText()
        }

        if (this.hasBlockingCertifications) {
          if (this.$route.name !== 'certification') {
            await this.$router.replace({
              name: 'certification',
            })
          }
        }

        if (this.onlyDiagnostics && this.$route.name === 'student-main') {
          await this.$router.replace({
            name: 'student-income-diagnostics',
          })
        }
      } finally {
        this.isLoading = false
      }

      await this.checkJustPaidContract()
      await this.checkDomenMovedInform()
    },

    async checkDomenMovedInform() {
      const needToShowModal = storageApi.get('needToShowModal')

      if (needToShowModal && !this.userSettings?.data?.isDomenMovedInformed) {
        this.$refs.ModalDomenMoved.show()

        storageApi.remove('needToShowModal')

        await this.updateUserSettings({
          isDomenMovedInformed: true,
        })
      }
    },

    async checkJustPaidContract() {
      if (this.hasJustPaidContractFe || this.hasJustPaidContractDemoFeSimple) {
        this.profileUpdate.student = this.profile

        await this.$nextTick()

        this.$refs.modalProfileUpdateDemoFeSimple.show()
      }
    },

    /**
     * @param {string|null} estimatedTime
     */
    getTextForDemo(estimatedTime) {
      this.demoAccessTimeText = `${this.$t(
        'layout.main.demo_access_will_end_in',
      )} ${estimatedTime}.`
    },

    tickDemoTimer() {
      this.demoTimerSecondsLeft -= INTERVAL_TIME / 1000
      const estimatedTime = getEstimatedTime(this.demoTimerSecondsLeft)

      if (estimatedTime) {
        this.getTextForDemo(estimatedTime)
      } else {
        let errorText = this.$t('layout.main.demo_access_ended')

        if (this.demoMessage) {
          errorText += ` ${this.demoMessage}`
        }

        this.$router.push({
          name: 'logout',
        })

        this.$toasted.show(errorText, {
          type: 'error',
          duration: 0,
          action: {
            text: 'Ok',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            },
          },
        })
      }
    },

    setTimerForDemoText() {
      this.demoTimerSecondsLeft = this.demoAccessTime

      this.tickDemoTimer()

      this.demoTimer = setInterval(this.tickDemoTimer, INTERVAL_TIME)
    },

    releaseTimerForDemoText() {
      clearInterval(this.demoTimer)
    },

    /**
     * @param {boolean} isAsideOpen
     */
    toggleAside(isAsideOpen) {
      this.updateUserSettings({
        isAsideOpen,
      })
    },

    onChangeTabNav(tabId) {
      this.setCurrentTab(tabId)
    },

    onChangeFrameRateUsState(isFrameOpened) {
      this.isFrameRateUsOpened = isFrameOpened
    },

    onChangeGrade() {
      this.fetchInitialData()
    },

    async fetchInitialData() {
      await this.fetchProfile()
      await this.init()
    },

    onVisibilityChanged(value) {
      this.isBtnInfoShifted = value
    },

    onClickBtnInfo(blockName) {
      this.$refs[blockName].show()
    },
  },
}
</script>
