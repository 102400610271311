import { RESET_STATE } from 'Helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  addBottomNotification(state, notification) {
    state.bottomNotifications.push(notification)
  },

  popBottomNotification(state) {
    state.bottomNotifications.pop()
  },
}
