export const onlineSchoolRoutes = [
  {
    path: '/student/online-school',
    name: 'student-online-school',
    component: () =>
      import(
        /* webpackChunkName: "student-online-school" */
        '@frontend/pages/student/OnlineSchool.vue'
      ),
  },
  {
    path: 'online-school/purchases/:purchaseId(\\d+)',
    name: 'student-online-school-the-purchase',
    component: () =>
      import(
        /* webpackChunkName: "student-online-school-the-purchase" */
        '@frontend/pages/student/TheOnlineSchoolPurchase.vue'
      ),
  },
]
