import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProfile(state, payload) {
    state.profile = payload
  },

  setProfileValue(state, { key, value }) {
    Vue.set(state.profile, key, value)
  },

  setProfilePassword(state, payload) {
    state.passwords = payload
  },

  setUserSettings(state, payload) {
    state.userSettings = payload
  },
}
