export const clientOnlineSchoolRoutes = [
  {
    path: 'online-school',
    name: 'client-online-school',
    component: () =>
      import(
        /* webpackChunkName: "client-online-school" */
        '@frontend/pages/client/OnlineSchool.vue'
      ),
  },

  {
    path: 'online-school/purchases/:purchaseId(\\d+)',
    name: 'client-online-school-the-purchase',
    component: () =>
      import(
        /* webpackChunkName: "client-online-school-the-purchase" */
        '@frontend/pages/client/TheOnlineSchoolPurchase.vue'
      ),
  },
]
