<template>
  <BottomNotification ref="BottomNotification">
    <div :class="$style.wrapper">
      <div :class="$style.text" class="mr-0 mr-lg-4">
        <div class="text-blue">
          {{ t('text_blue') }}
        </div>
        <div>{{ t('text') }}</div>
      </div>

      <div :class="$style.btnWrapper">
        <ElButton
          class="btn-primary mb-2 mb-lg-0 mr-lg-2"
          :label="t('btn_choose')"
          @click="onClickGoToChooseForm"
        />

        <ElButton
          class="btn-outline-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </div>
  </BottomNotification>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import BottomNotification from '@frontend/components/common/BottomNotification.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlockChooseEducationForm',

  components: {
    ElButton,
    BottomNotification,
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.$refs.BottomNotification.show()
    },

    hide() {
      this.$refs.BottomNotification.hide()
    },

    async onClickGoToChooseForm() {
      this.hide()
      await this.$router.push({
        name: 'client-request-new',
      })
    },

    onClickBtnCancel() {
      this.hide()
    },

    t(name) {
      return this.$t(`components.bottom_notification.choose_form.${name}`)
    },
  },
})
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: $lg) {
    flex-direction: row;
  }
}

.btnWrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;

  @media (min-width: $lg) {
    width: unset;
    align-items: center;
    flex-direction: row;
  }
}

.text {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (min-width: $lg) {
    width: unset;
    margin-bottom: 0px;
  }
}
</style>
