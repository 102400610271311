import ApiBase from 'ApiRest/ApiBase'
import { iblschoolApiUrlConditional } from 'Helpers/const/iblschoolUrl'

export const ApiShowcaseOnlineLessonJoin = new (class extends ApiBase {
  /**
   * Получить ссылку для входа в конференцию
   * @param {number} onlineLessonId
   * @returns {string}
   */
  get(onlineLessonId) {
    return `${iblschoolApiUrlConditional}/rest/showcase/account/online-lessons/${onlineLessonId}/join`
  }
})()
