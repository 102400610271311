<template>
  <BtnInfo
    :is-btn-info-shifted="isBtnInfoShifted"
    :notifications="bottomNotifications"
    @click="onClickInfoBtn"
  />
</template>

<script>
import BtnInfo from '@frontend/ui/BtnInfo.vue'
import { mapState, mapActions } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BtnInfoClient',

  components: {
    BtnInfo,
  },

  props: {
    isBtnInfoShifted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('client/notifications', [
      'bottomNotifications',
    ]),
  },

  methods: {
    ...mapActions('client/notifications', [
      'popBottomNotification',
    ]),

    async onClickInfoBtn() {
      const notification = await this.popBottomNotification()

      this.$emit('click', notification.blockName)
    },
  },
})
</script>
