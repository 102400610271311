<template>
  <div :class="[$style.btn, isVisible, isShifted]" @click="onClickInfoBtn">
    <div v-if="isRendered.count" :class="$style.label">
      {{ notificationCount }}
    </div>

    <svg
      width="2"
      height="16"
      viewBox="0 0 2 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 0C1.55228 0 2 0.447715 2 1V10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10V1C0 0.447715 0.447715 0 1 0Z"
        fill="#E71B7D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 13C1.55228 13 2 13.4477 2 14V15C2 15.5523 1.55228 16 1 16C0.447715 16 0 15.5523 0 15V14C0 13.4477 0.447715 13 1 13Z"
        fill="#E71B7D"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BtnInfo',

  props: {
    isBtnInfoShifted: {
      type: Boolean,
      default: false,
    },

    notifications: {
      type: Array,
      required: true,
    },
  },

  computed: {
    notificationCount() {
      return this.notifications.length
    },

    hasNotifications() {
      return this.notificationCount > 0
    },

    isRendered() {
      return {
        count: this.hasNotifications,
      }
    },

    isVisible() {
      return this.hasNotifications ? this.$style.visible : ''
    },

    isShifted() {
      return this.isBtnInfoShifted ? this.$style.shifted : ''
    },
  },

  methods: {
    onClickInfoBtn() {
      this.$emit('click')
    },
  },
})
</script>

<style lang="scss" module>
@import '~@frontend/scss/variables';

.btn {
  position: fixed;
  right: 36px;
  bottom: -100%;
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 24px rgb(51 47 62 / 8%);
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}

.visible {
  bottom: 20px;
}

.shifted {
  right: 84px;
}

.label {
  position: absolute;
  top: -3px;
  right: -3px;
  display: flex;
  width: 16px;
  height: 16px;
  font-size: 11px;
  font-weight: 700;
  color: $white;
  background: $pink;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
</style>
