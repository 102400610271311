import ApiBase from 'ApiRest/ApiBase'
import { iblschoolApiUrlConditional } from 'Helpers/const/iblschoolUrl'

export const ApiOnlineLessonJoin = new (class extends ApiBase {
  /**
   * Получить ссылку для входа в конференцию
   * @param {number} onlineLessonId
   * @returns {string}
   */
  get(onlineLessonId) {
    return `${iblschoolApiUrlConditional}/rest/account/student/online-lessons/${onlineLessonId}/join`
  }
})()
