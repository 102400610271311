import { studentTypes } from '@frontend/consts/client/onlineSchool/studentTypes'
import { gradeOptions } from '@frontend/helpers/onlineSchool/grades'
import { ApiOnlineSchoolCheckout } from 'ApiRest/Api/OnlineSchool/Checkout'
import { ApiInvoicePeriodTypesOptions } from 'ApiRest/Api/OnlineSchool/Option/InvoicePeriodTypes'
import { ApiOnlineSchoolStudentList } from 'ApiRest/Api/OnlineSchool/Option/StudentList'
import { ApiOnlineSchoolProductList } from 'ApiRest/Api/OnlineSchool/ProductList'
import { ApiOnlineSchoolPurchaseGenerateInvoices } from 'ApiRest/Api/OnlineSchool/Purchase/GenerateInvoices'
import { ApiOnlineSchoolStudentCheckout } from 'ApiRest/Api/OnlineSchool/StudentCheckout'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchProducts({ getters, commit }) {
    commit('setIsLoadingProducts', true)
    const { data } = await ApiOnlineSchoolProductList.get(getters.productParams)

    commit('setProducts', data.items)
    commit('setCurrentOrder', data.currentOrder)
    commit('setInitialOrder', data)
    commit('setIsLoadingProducts', false)
  },

  async fetchStudents({ commit, dispatch }) {
    const { data } = await ApiOnlineSchoolStudentList.get()

    commit('setOptionsList', {
      path: 'students',
      value: data,
    })

    dispatch('setDefaultStudent')
  },

  setDefaultStudent({ commit, getters }) {
    if (getters.hasStudents) {
      const studentHasOnlineSchoolOrder = getters.studentsOptions.find(
        (student) => student.hasOnlineSchoolOrder,
      )

      commit(
        'setStudent',
        studentHasOnlineSchoolOrder ?? getters.studentsOptions[0],
      )

      commit('setStudentType', studentTypes[1])
    } else {
      commit('setStudentType', studentTypes[0])
    }
  },

  async fetchPaymentTypes({ commit }) {
    const { data } = await ApiInvoicePeriodTypesOptions.get()

    commit('setOptionsList', {
      path: 'paymentTypes',
      value: data,
    })
    commit('setInvoicePeriodType', data[0])
  },

  async checkout({ getters, commit }) {
    const { data } = await ApiOnlineSchoolCheckout.post(
      getters.checkoutParams.client,
    )

    commit('setPurchase', data)
  },

  async createInvoice({ state, commit }) {
    let invoices = []

    if (state.purchase.invoices.length > 0) {
      invoices = state.purchase.invoices
    } else {
      const { data } = await ApiOnlineSchoolPurchaseGenerateInvoices.put(
        state.purchase.id,
      )

      invoices = data
    }

    commit('setInvoices', invoices)
  },

  async studentCheckout({ getters, commit }) {
    const { data } = await ApiOnlineSchoolStudentCheckout.post(
      getters.checkoutParams.student,
    )

    commit('setPurchase', data)
  },

  async setStudentInfo({ rootState, commit }) {
    const { profile } = rootState.student.profile

    const data = {
      id: null,
      firstName: profile.firstName,
      lastName: profile.lastName,
      middleName: profile.middleName,
      email: profile.email,
      grade:
        gradeOptions.find((grade) => grade.name === profile.grade) ??
        gradeOptions[0],
    }

    commit('setConsumer', data)
  },
}
