import ApiDownloadFile from 'Api/Download'
import { ApiOnlineSchoolPurchase } from 'ApiRest/Api/OnlineSchool/Purchase'
import { ApiOnlineSchoolPurchaseClientData } from 'ApiRest/Api/OnlineSchool/Purchase/ClientData'
import { ApiOnlineSchoolPurchaseGenerateInvoices } from 'ApiRest/Api/OnlineSchool/Purchase/GenerateInvoices'
import { ApiOnlineSchoolPurchaseItemDocument } from 'ApiRest/Api/OnlineSchool/Purchase/PurchaseItemDocument'
import ApiOption from 'ApiRest/Api/Option'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchPurchase({ commit }, purchaseId) {
    const { data } = await ApiOnlineSchoolPurchase.get(purchaseId)

    commit('setPurchase', data)

    return data
  },

  async fetchClientData({ commit, state }) {
    const { data } = await ApiOnlineSchoolPurchaseClientData.get(
      state.purchaseId,
    )

    commit('setClientData', data)
  },

  async updateClientData({ commit, state }) {
    const { data } = await ApiOnlineSchoolPurchaseClientData.patch(
      state.purchaseId,
      state.customer,
    )

    commit('setClientData', data)
  },

  async fetchOptionCitizenshipList({ commit }) {
    const { data } = await ApiOption.getCitizenships()

    commit('setOptionValue', {
      path: 'citizenshipList',
      value: data,
    })
  },

  async fetchOptionCountryList({ commit }) {
    const { data } = await ApiOption.getCountries()

    commit('setOptionValue', {
      path: 'countryList',
      value: data,
    })
  },

  async fetchPurchaseItemDocument(
    context,
    { purchaseId, purchaseItemId, documentTemplateId },
  ) {
    const response = await ApiOnlineSchoolPurchaseItemDocument.get({
      purchaseId,
      purchaseItemId,
      documentTemplateId,
    })

    DownloadHelper.downloadBlob(response)
  },

  fetchSignedDocumentUrl(context, fileId) {
    return ApiDownloadFile.getFileUrl(fileId)
  },

  async createInvoice({ state, commit }) {
    const { data } = await ApiOnlineSchoolPurchaseGenerateInvoices.put(
      state.purchaseId,
    )

    commit('setInvoices', data)
  },
}
