<template>
  <ElModal :name="name" :adaptive="true" class="modal-content">
    <ElButton class="btn-img vm--modal-close" @click="hide">
      <img :src="icons.iconClose" alt="icon close" />
    </ElButton>

    <h3 class="h3">Личный кабинет переехал</h3>

    <div class="mt-4">
      Теперь он находится по адресу
      <span class="font-weight-bold">lk.ibls.one</span>. Уже перенаправили вас.
    </div>

    <div class="mt-4">
      <ElButton class="btn-primary" label="Спасибо" @click="hide" />
    </div>
  </ElModal>
</template>

<script>
import ElButton from '@frontend/ui/ElButton.vue'
import ElModal from '@frontend/ui/ElModal.vue'
import iconClose from 'Static/images/@frontend/common-lk/icons/close.svg'

export default {
  name: 'ModalExtraInfo',

  components: {
    ElModal,
    ElButton,
  },

  data() {
    return {
      name: 'ModalDomenMoved',

      icons: {
        iconClose,
      },
    }
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    hide() {
      this.$modal.hide(this.name)
    },
  },
}
</script>
